/** @jsx jsx */
import { jsx } from 'theme-ui';
import { keyframes } from '@emotion/react';
import useDimensions from 'react-cool-dimensions';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { useLangKey } from '../../utils/lang';
import { navigate } from '@reach/router';

function randomIntFromInterval(min, max) {
	return Math.round(Math.random() * (max - min) + min);
}

function shuffle(a) {
	for (let i = a.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[ a[i], a[j] ] = [ a[j], a[i] ];
	}
	return a;
}

const grow = keyframes({
	from: { transform: `scale(0.0)` },
	to: { transform: 'scale(1.0)' }
});

//const grow2 = keyframes({ from: { transform: 'scale(1.0)' }, to: { transform: 'scale(1.2)' } });
//const grow_tiny = keyframes({ from: { transform: 'scale(1.0)' }, to: { transform: 'scale(1.07)' } });

const FlowerFace = ({ face, flower, width, delay, y, x }) => {
	const [ visible, setVisible ] = useState(false);
	useEffect(() => {
		setInterval(() => {
			setVisible(true);
		}, delay);
	}, []);

	if (!visible) return <React.Fragment />;
	return (
		<div
			sx={{
				animation: `${grow} 1s ease-in`
			}}
		>
			<FlowerFaceComp flower={flower} face={face} width={width} />
		</div>
	);
};

export const FlowerFaceComp = ({ face, flower, width }) => {
	const [ enter, setEnter ] = useState(false);

	// const style = enter
	// 	? {
	// 			zIndex: 1010
	// 		}
	// 	: {};

	// const animation = enter
	// 	? {
	// 			animation: `${grow2} 1s ease-in`,
	// 			animationFillMode: 'forwards'
	// 		}
	// 	: {
	// 			animation: `${grow2} 1s ease-in`,
	// 			//animationFillMode: 'forwards',
	// 			animationDirection: 'reverse'
	// 		};
	// const ani_tiny = enter
	// 	? {
	// 			animation: `${grow_tiny} 1s ease-in`,
	// 			animationFillMode: 'forwards'
	// 		}
	// 	: {};
	const top_style = enter ? { zIndex: 100 } : {};
	const style = enter
		? {
				transform: 'scale(1.5)'
			}
		: {
				transform: 'scale(1)',
				transitionDuration: '500ms'
			};
	const style2 = enter
		? {
				transform: 'scale(1.1)'
			}
		: {
				transform: 'scale(1)',
				transitionDuration: '500ms'
			};

	return (
		<div
			sx={{ ...top_style, position: 'relative', width, display: 'grid' }}
			onMouseOver={() => setEnter(true)}
			onMouseOut={() => setEnter(false)}
		>
			<div
				sx={{
					transition: 'transform 1.5s ease-in',
					...style, //...animation,
					gridColumn: 1,
					gridRow: 1
				}}
			>
				<GatsbyImage image={flower} alt={''} />
			</div>

			<div
				sx={{
					//	...ani_tiny,
					transition: 'transform 1.5s ease-in',
					...style2,
					gridColumn: 1,
					gridRow: 1
				}}
			>
				<GatsbyImage image={face} alt={''} />
			</div>
		</div>
	);
};

const groupPageUrl = { de: 'gruppe', en: 'group', es: 'grupo', fr: 'groupe' };

function addMember(item, arr, y, x, width, delay, langKey, linkedMembers) {
	const face = getImage(item.content.face.asset);
	const flower = getImage(item.content.flower.asset);
	const name = item.name;
	const id = item.id;
	const comp = <FlowerFace face={face} flower={flower} width={width} delay={delay} x={x} y={y} />;
	const link =
		id === linkedMembers.a.id || id === linkedMembers.b.id
			? `${linkedMembers.b.name} & ${linkedMembers.a.name}`
			: name;
	arr.push(
		<div sx={{ position: 'absolute', top: y + 'px', left: x + 'px' }}>
			<Link to={'/' + langKey + '/' + groupPageUrl[langKey] + '#' + link}>{comp}</Link>
		</div>
	);
}

const FlowerGrid = ({ members }) => {
	const data = useStaticQuery(graphql`
		query LinkedMembersQuery {
			allSanityLinkedMembers {
				nodes {
					b {
						id
						name
					}
					a {
						id
						name
					}
				}
			}
		}
	`);


	const { observe, width, height } = useDimensions({});
	const langKey = useLangKey();


	const linkedMembers = data.allSanityLinkedMembers.nodes[0];

	shuffle(members);

	const calc = () => {
		const n = members.length;

		if (width <= 0 || height <= 0) return [];

		const factor = Math.sqrt(n / (width * height));

		var row_n = Math.round(height * factor);
		var col_n = Math.round(width * factor);

		const rest = n - row_n * col_n;

		if (col_n >= row_n) col_n += Math.ceil(rest / row_n);
		else row_n += Math.ceil(rest / col_n);

		const row_space = height / row_n;
		const col_space = width / col_n;

		const img_width = Math.min(row_space, col_space) * 1.3;

		const col_rand_amp = col_space / 4;
		const row_rand_amp = row_space / 4;

		var arr = [];
		var index = 0;
		for (var j = 0; j < row_n; j++) {
			for (var i = 0; i < col_n; i++) {
				if (index >= n) break;

				const col_rand =
					i == 0
						? randomIntFromInterval(0, col_rand_amp)
						: i == col_n - 1
							? randomIntFromInterval(-col_rand_amp, 0)
							: randomIntFromInterval(-col_rand_amp, col_rand_amp);

				const row_rand =
					j == 0
						? randomIntFromInterval(0, row_rand_amp)
						: j == row_n - 1
							? randomIntFromInterval(-row_rand_amp, 0)
							: randomIntFromInterval(-row_rand_amp, row_rand_amp);

				const x = i * col_space + col_rand;
				const y = j * row_space + row_rand;
				const item = members[index].node;
				const delay = Math.random() * 3000;
				addMember(item, arr, y, x, img_width, delay, langKey, linkedMembers);
				index++;
			}
		}
		return arr;
	};

	const arr = calc();
	return (
		<div sx={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }} ref={observe}>
			{arr}
		</div>
	);
};

export { FlowerGrid };
