/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql, Link } from 'gatsby';
import Layout from '../../gatsby-theme-catalyst-core/components/layout';
import { FlowerFaceComp } from '../home-page/faces';
import { keyframes } from '@emotion/react';
import React from 'react';
import { BreakPoint } from '../group-page/BreakPoint';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { useLangKey } from '../../utils/lang';



const BioQuery = ({ data, location, pageContext }) => {
	const hue = keyframes({
		from: {
			filter: 'hue-rotate(0deg)'
		},

		to: {
			filter: 'hue-rotate(360deg)'
		}
	});

	const members = data.allSanityMember.edges; //.filter((m)=>m.content.face && m.content.flower);
	const brkPoint = useBreakpointIndex();
	return (
		<Layout pathname={location.pathname} pageContext={pageContext}>
			{/* <BreakPoint /> */}
			<div
				sx={{
					display: 'grid',
					gridTemplateColumns: [ '50vw 50vw', '50vw 50vw' ],
					width: '100vw',
					xOverflow: 'hidden',
					justifyContent: 'space-around',
					gridColumnGap: [ 0, 20, 80 ],
					letterSpacing: '0.26em',
					mt: [ 5, 5 ]

				}}
			>
				{members.map((m, index) => {
					const { flower } = m.node.content;
					const { face } = m.node.content;
					const name = m.node.fullName;
			        const {bioSlug} = m.node;
					const style =
						index % 2 !== 0 ? { top: [ '76%', '70%', '80%', null, '65%' ], position: 'relative' } : undefined;
					const end = index % 2 === 0 ? { justifySelf: 'end' } : undefined;
					return (
						<React.Fragment>
							<div sx={{pb:['23%','unset'], height: ['100%','460px'], ...style, ...end, px: [ '10%', 'unset' ] }}>
								<Face name={name} face={face} flower={flower} bioSlug={bioSlug}/>
							</div>
							{(/*brkPoint === 0 ||*/ index % 2 !== 0) && (
								<div
									sx={{
										height: ['9px','15px'],
										width: '120%',
										background: `hsl(${(index * 10) % 360}, 100%, 75%)`,
										gridColumn: ['1 / span 2' ],
										transform: 'rotate(340deg)',
										position: 'relative',
										top: ['-71px','-822%'],
										left: '-10%'
										// animation: `10s ${hue} infinite`
									}}
								/>
							)}
						</React.Fragment>
					);
				})}
			</div>
		</Layout>
	);
};

export const query = graphql`
	query SanityBiosQuery {
		allSanityMember(
			filter: {
				content: { flower: { asset: { id: { ne: null } } }, face: { asset: { id: { ne: null } } } }
				isBioVisible: { eq: true }
			}
			sort: { fields: lastName, order: ASC }
		) {
			edges {
				node {
					id
					fullName
					bioSlug
					content {
						face {
							asset {
								gatsbyImageData(width: 400, placeholder: NONE)
							}
						}
						flower {
							asset {
								gatsbyImageData(width: 400, placeholder: NONE)
							}
						}
					}
				}
			}
		}
		allSanityHomePage {
			nodes {
				seoTitle
			}
		}
	}
`;

export default BioQuery;

const Face = ({ name, face, flower, bioSlug }) => {
	const langKey = useLangKey();

	const blackShadow = {
		color: 'black',
		fontFamily: 'Oswald',
		textTransform: 'uppercase',
		maxHeight: [ '140px', '360px' ],
		fontWeight: 'bold',
		textDecoration: 'underline',
		// letterSpacing: '0.06em',
		fontSize: ['11px',3,3],
		writingMode: 'vertical-lr',
		textOrientation: 'mixed',
		position: 'relative',
		top: [ '-30px', 'unset' ]
		// textShadow: '0 -1px 0 #000, 0 1px 0 #000, 1px 0 0 #000, -1px 0 0 #000, -4px 2px 0 #000',
	};
	return (
		<Link
			to={'/' + langKey + '/' + 'bio' + '/' + bioSlug}
			sx={{
				color: 'inherit',
				'&.visited': {
					color: 'inherit'
				}
			}}
		>
			<div sx={{ display: 'flex' }}>
				<FlowerFaceComp
					sx={{ maxWidth: '400px' }}
					flower={flower.asset.gatsbyImageData}
					face={face.asset.gatsbyImageData}
					width={[ "100%", 280, '34vw', 300, 400 ]}
				/>
				<div sx={blackShadow}>{name}</div>
			</div>
		</Link>
	);
};
